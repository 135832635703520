<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :maxLength="50"
        :label="$t('ACADEMICO.NOME')"
        :placeholder="$t('ACADEMICO.NOME')"
        required
      />
    </div>
    <div class="col-12">
      <input-select-search
        ref="disciplinaCurriculoId"
        v-model="form.disciplinaCurriculoId"
        :label="$t(`ACADEMICO.DISCIPLINA_CURRICULAR`)"
        :options="opcoes.disciplinaCurricular"
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-text
        ref="cargaHoraria"
        v-model="form.cargaHorario"
        type="number"
        :label="$t('ACADEMICO.CARGA_HORARIA')"
        :placeholder="$t('ACADEMICO.CARGA_HORARIA')"
        :maxLength="6"
        :showLengthLimit="false"
        :min="1"
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-select-search
        ref="idioma"
        v-model="form.idiomaId"
        :label="$t(`ACADEMICO.IDIOMA`)"
        :options="opcoes.idioma"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-select-search
        ref="sexo"
        v-model="form.sexoId"
        :label="$t(`ACADEMICO.SEXO`)"
        :options="opcoes.genero"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Service:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import DisciplinaCurriculo from '@/common/services/curriculo-escolar/disciplina.service';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getGeneros();
    this.getIdiomas();
    this.getDisciplinaCurricular();
    this.$emit('refs', this.$refs);
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        idioma: [],
        genero: [],
        disciplinaCurricular: [],
      },
    };
  },
  watch: {
    'form.idiomaId': 'encontrarDescricaoIdioma',
    'form.sexoId': 'encontrarDescricaoGenero',
  },
  methods: {
    getDisciplinaCurricular() {
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.obterTodas()
        .then(({ data }) => {
          if (data.itens.length) {
            data.itens = data.itens.map((row) => ({
              ...row,
              text: row.descricao,
              value: row.id,
            }));
            this.opcoes.disciplinaCurricular = data.itens;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getIdiomas() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('idiomas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.idioma = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGeneros() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('sexo')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.genero = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES
    encontrarDescricaoIdioma() {
      if (!this.form.idiomaId) return;
      this.opcoes.idioma.forEach((element) => {
        element.id == this.form.idiomaId ? (this.form.idioma = element.id) : {};
      });
    },
    encontrarDescricaoGenero() {
      if (!this.form.sexoId) return;
      this.opcoes.genero.forEach((element) => {
        element.id == this.form.sexoId ? (this.form.sexo = element.id) : {};
      });
    },
  },
};
</script>
