<template>
  <div class="mb-3">
    <b-table
      :fields="tabela.fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <!-- <b-dropdown-item @click="irPara('academico-turma-x-disciplina',item.item)">
                        Ir para às aulas
                    </b-dropdown-item> -->
          <b-dropdown-item @click="excluir(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'codigo',
            label: this.$t('GERAL.CODIGO'),
            sortable: true,
          },
          {
            key: 'descricao',
            label: this.$t('GERAL.DISCIPLINA'),
            sortable: true,
          },
          {
            key: 'cargaHorario',
            label: this.$t('GERAL.CARGA_HORARIA'),
            sortable: true,
          },
          {
            key: 'idiomaDescricao',
            label: this.$t('GERAL.IDIOMA'),
            sortable: true,
          },
          {
            key: 'sexoDescricao',
            label: this.$t('GERAL.GENERO'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    editar(disciplina) {
      this.$emit('editar', 'disciplina', disciplina);
    },
    excluir(disciplina) {
      this.$emit('excluir', disciplina);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
