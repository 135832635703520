<template>
  <b-card>
    <div class="row">
      <div class="col-12 mb-5">
        <dados-turma :turma="dados.turma" />
      </div>
      <div class="col-12">
        <b-tabs content-class="mt-3">
          <b-tab title="Alunos" active>
            <alunos />
          </b-tab>
          <b-tab title="Disciplinas">
            <titulo title="Disciplinas" />
            Dados falsos. Estamos construindo, eventualmente terminamos :)
            <tabela-disciplinas
              :items="disciplinasFalsas"
              @editar="openModal"
            />
          </b-tab>
          <b-tab title="Professores">
            Estamos construindo, eventualmente terminamos :)
          </b-tab>
          <b-tab title="Rendimento">
            Estamos construindo, eventualmente terminamos :)
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- modais -->
    <modal-disciplina
      :form="modais.disciplinaDados"
      :exibir="modais.disciplina"
      @fechar="closeModal('disciplina')"
    />
  </b-card>
</template>
<script>
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

import Titulo from '@/components/headers/Titulo.vue';
import Alunos from './components/Alunos.vue';
import DadosTurma from './components/DadosTurma.vue';
import TabelaDisciplinas from '@/views/academico/cej/estrutura/disciplinas/components/Tabela.vue';
import ModalDisciplina from '@/views/academico/cej/estrutura/disciplinas/components/Modal.vue';

import TurmaService from '@/common/services/academico/turma.service';

export default {
  props: ['turma'],
  components: {
    Titulo,
    Alunos,
    DadosTurma,
    TabelaDisciplinas,
    ModalDisciplina,
  },
  mounted() {
    this.getTurma();
    breadcrumb.definir(this.$store, [{ titulo: `Turma ${this.turmaNome}` }]);
  },
  computed: {
    turmaNome() {
      // TODO (BUG): não retornando o nome da turma no breadcrumb :()
      return !this.turma ? '' : this.turma.descricao;
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      modais: {
        disciplina: false,
        disciplinaDados: {},
      },
      dados: {
        turma: {},
      },
      disciplinasFalsas: [
        {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          descricao: 'Biologia',
          emenda: 'emenda aqui',
          planoEnsino: 'plano ensino aqui',
          cargaHorario: 33,
          idioma: 'Alemão',
          sexo: 'Todos',
        },
      ],
    };
  },
  methods: {
    // FUNÇÕES MODAL:
    getTurma() {
      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorId(this.id)
        .then(({ data }) => {
          this.dados.turma = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(item) {
      item
        ? (this.modais.disciplinaDados = item)
        : (this.modais.disciplinaDados = {});
      this.modais.disciplina = true;
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
  },
};
</script>
