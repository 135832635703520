<template>
  <div>
    <titulo-btns :title="turma.descricao">
      <b-button @click="irPara('academico-turmas')" variant="primary"
        >Retornar à lista de turmas</b-button
      >
    </titulo-btns>
    <div>
      <p class="m-0"><b>Vagas:</b> {{ turma.vagas }}</p>
      <p class="m-0"><b>Carga Horária:</b> {{ turma.cargaHoraria }}</p>
      <p class="m-0"><b>Curso da turma:</b> {{ turma.curso }}</p>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

// Components:
import TituloBtns from '@/components/headers/TituloBtns.vue';
export default {
  props: ['turma'],
  components: { TituloBtns },
  methods: {
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
