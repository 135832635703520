<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="'Disciplina'"
    :campos="$refs"
    id="modalDisciplina"
  >
    <disciplinas-form :form="form" @refs="capturarRefs" />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import DisciplinasService from '@/common/services/academico/disciplinas.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import DisciplinasForm from './Form.vue';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    DisciplinasForm,
    Modal,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.id ? this.editarDisciplina() : this.criarDisciplina();
    },
    criarDisciplina() {
      this.$store.dispatch(START_LOADING);
      DisciplinasService.criar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarDisciplina() {
      this.form.sexo = this.form.sexoId;
      this.form.idioma = this.form.idiomaId;

      this.$store.dispatch(START_LOADING);
      DisciplinasService.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
