<template>
  <div>
    <titulo title="Alunos" />
    Dados falsos. Estamos construindo, eventualmente terminamos :)
    <b-table :fields="alunos.fields" :items="alunos.items">
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="verAluno(item.item)">
              Ver perfil completo do aluno
            </b-dropdown-item>
            <b-dropdown-item @click="verAluno(item.item)">
              Ver frequência do aluno
            </b-dropdown-item>
            <b-dropdown-item @click="verAluno(item.item)">
              Ver notas do aluno
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import Titulo from '@/components/headers/Titulo.vue';
export default {
  components: {
    Titulo,
  },
  data() {
    return {
      alunos: {
        fields: [
          {
            key: 'nome',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'sexo',
            label: 'Gênero',
            sortable: true,
          },
          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
        items: [
          {
            nome: 'Ana Clara',
            sexo: 'Feminino',
            dataNascimento: '01/01/2016',
          },
        ],
      },
    };
  },
  methods: {
    verAluno() {},
  },
};
</script>
