import ApiService from "@/common/api/api.service";

let apiBasePath = 'DisciplinaCurriculo'

const DisciplinaCurriculo = {
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (disciplinaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${disciplinaId}`)
        return data
    },
    // FUNÇÕES DE VINCULAR DISCIPLINA CEJ COM DISCIPLINA INSTITUIÇÃO
    async vincular (form) {
        let { data } = await ApiService.post(`${apiBasePath}/disciplina/adicionar`, form)
        return data
    },
    async desvincular (form) {
        let { data } = await ApiService.delete(`${apiBasePath}/disciplina/remover?DisciplinaCurriculoId=${form.disciplinaCurriculoId}&DisciplinaInternaId=${form.disciplinaInternaId}`)
        return data
    },
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    async obterPorId (disciplinaId) {
        let { data } = await ApiService.get(`${apiBasePath}/${disciplinaId}`)
        return data
    },
    async obterPorFiltro (filtro, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    Expressao: filtro,
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina,
                },
            }
        )
        return data
    },
    async obterTodas () {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    Expressao: '',
                    TamanhoDaPagina: 1000,
                    NumeroDaPagina: 1,
                },
            }
        )
        return data
    }
}

export default DisciplinaCurriculo;